import React from 'react';
import styled, { css } from 'styled-components';
import { Select as select } from 'antd';
import propTypes from 'prop-types';
import Icon from '../../Icon';
import themes from '../../Themes';
import './index.less';

/** @type {select} */
const Select = styled(select)`
  &&&.ant-select-multiple,
  &&&.ant-select-single {
    font-family: ${themes['font-sans-book']};

    ${(props) =>
      props &&
      props.size === 'small' &&
      css`
        min-height: 36px;

        .ant-select-selection-item {
          height: 26px;
        }
      `};

    ${(props) =>
      props &&
      props.size === 'middle' &&
      css`
        min-height: 40px !important;
      `};

    ${(props) =>
      props &&
      props.size === 'large' &&
      css`
        min-height: 50px;

        .ant-select-selection-item {
          height: 40px;
        }
      `};

    .ant-select {
      &-selector {
        box-shadow: none !important;
        align-items: center;
        border-radius: 0;
        border: 1px solid ${themes.I50};
        color: ${themes.I90};
        font-size: ${themes['text-4-size']};
        font-family: ${themes['font-sans-book']};
        padding: 0 16px;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${themes.astral};
        }

        ${(props) =>
          props.width &&
          css`
            width: ${props.width}px;
          `};
      }

      &-selection {
        &-placeholder {
          color: ${themes.I50};
        }

        &-search {
          display: flex;
          align-items: center;
          right: 32px;
          left: 16px;
        }
      }

      &-arrow {
        right: 17px !important;
        color: ${themes.I90};
      }
    }

    &.ant-select {
      &-open {
        .ant-select {
          &-selector {
            border-color: ${themes.astral} !important;
          }

          &-selection-item {
            color: ${themes.indigo};
          }
        }
      }

      &-disabled {
        .ant-select-selector {
          background: ${themes.I10};
        }
      }

      &-focused {
        .ant-select-selector {
          border-color: ${themes.astral};
        }
      }

      &-status-error,
      &-status-success,
      &-focused {
        .ant-select-arrow span {
          margin-inline-end: 0;
        }
      }
    }

    &:not(.ant-select-status-error) {
      .ant-form-item-feedback-icon {
        display: none;
      }
    }
  }

  &&&&.ant-select-multiple {
    .ant-select {
      &-selector {
        padding-left: 4px;
        padding-right: 36px;
      }

      &-clear {
        inset-inline-end: 17px;
      }

      &-selection {
        &-placeholder {
          left: 16px;
        }

        &-item {
          align-items: center;
          line-height: inherit;
          margin-inline-end: 4px;
          border: ${themes['border-width-sm']} ${themes['border-style-base']};
          border-radius: ${themes['border-radius-sm']};

          &-remove {
            line-height: 0;
            color: ${themes.I90};

            svg {
              width: 15px;
              height: 15px;
            }

            .anticon-close {
              line-height: 0;
            }
          }

          &-content {
            color: ${themes.I90};
            font-size: 14px;
            line-height: 18px;
            font-weight: normal;
            margin-right: 7px;
          }

          &-disabled {
            .ant-select-selection-item-content {
              color: ${themes.I30};
            }
          }

          ${(props) =>
            props &&
            props.type === 'light' &&
            css`
              background: ${themes.I05};
              border-color: ${themes.I20};
            `};

          ${(props) =>
            props &&
            props.type === 'success' &&
            css`
              background: ${themes['light-green']};
              border-color: ${themes['light-green']};
            `};

          ${(props) =>
            props &&
            props.type === 'danger' &&
            css`
              background: ${themes['light-red']};
              border-color: ${themes['light-red']};
            `};

          ${(props) =>
            props &&
            props.type === 'warning' &&
            css`
              background: ${themes['gold-medium-light']};
              border-color: ${themes['gold-medium-light']};
            `};

          ${(props) =>
            props &&
            props.type === 'primary' &&
            css`
              background: ${themes.B10};
              border-color: ${themes.B10};
            `};

          ${(props) =>
            props &&
            props.nonClosable &&
            css`
              padding-inline-end: 1px;

              .ant-select-selection-item-remove {
                display: none;
              }
            `};
        }

        &-search {
          margin: 0;

          &-input {
            margin-left: 0;
          }
        }

        &-overflow-item {
          &:first-child {
            .ant-select-selection-search-input {
              position: relative;
              left: 12px;
            }
          }
        }
      }
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background: ${themes.white};
      }
    }
  }
`;

Select.propTypes = {
  suffixIcon: propTypes.element,
  nonClosable: propTypes.bool,
  size: propTypes.string,
  type: propTypes.string,
  width: propTypes.string,
};

Select.defaultProps = {
  suffixIcon: <Icon type='chevron-down' color={themes.I90} size={12} />,
  nonClosable: false,
  size: 'middle',
  type: 'light',
};

export default Select;
